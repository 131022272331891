<style src="./social-links.scss" lang="scss"></style>

<template>
  <div class="social-links flex" role="navigation">
    <a rel="nofollow" href="https://github.com/gkatsanos/" target="_blank">
      <i class="icon icon-github"></i>
      github
    </a>
    <a rel="nofollow" href="http://stackoverflow.com/users/458060/george-katsanos" target="_blank">
      <i class="icon icon-stackoverflow"></i>
      stackoverflow
    </a>
    <a rel="nofollow" href="https://www.linkedin.com/in/georgekatsanos" target="_blank">
      <i class="icon icon-linkedin-square"></i>
      linkedin
    </a>
    <a rel="nofollow" href="https://medium.com/@gplusgr" target="_blank">
      <i class="icon icon-medium"></i>
      medium
    </a>
  </div>
</template>

<script>
export default {
  name: "Social",
};
</script>
